<template>
  <div class="cooperation-channel">
    <coming-soon />
  </div>
</template>

<script>
import ComingSoon from "../components/comingSoon";
export default {
  name: "cooperationChannel",
  components: {ComingSoon}
}
</script>

<style scoped>
.cooperation-channel {
  color: #11142C;
}
</style>